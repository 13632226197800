import { configureStore } from "@reduxjs/toolkit";
import { reducers as userReducers } from "../../src/redux/slice/user_slice";
import { reducers as binReducers } from "../../src/redux/slice/bin_slice";

const store = configureStore({
  reducer: {
    // loginuser: loginReducer,
    userLogin: userReducers.userLogin,
    binUpload: binReducers.binUpload,
    GetDevicesList: userReducers.GetDevicesList,
    addNewDevice: userReducers.addNewDevice,
    GetUsersList: userReducers.GetUsersList,
    DeleteDevice: userReducers.DeleteDevice,
    GetCardioList: userReducers.getCardioList,
    createNewCardio: binReducers.createNewCardio,
    GetOtaHistory: userReducers.GetOtaHistory,
    getPlanHistory: userReducers.getPlanHistory,
    cardioFeesUpdation: userReducers.cardioFeesUpdation,
    couponsFetch: userReducers.couponsFetch,
    cardioPayment: userReducers.cardioPayment,
    generateCoupons: userReducers.generateCoupons,
    deleteCoupon: userReducers.deleteCoupon,
    updateVoyanceLite: userReducers.updateVoyanceLite,
    updateUserPlan: userReducers.updateUserPlan,
    pendingReportList: userReducers.pendingReportList,
    deviceFilter: userReducers.deviceFilter,
    cardioFilterSearch: userReducers.cardioFilterSearch,
    searchUser:userReducers.searchUser,
    devicesUserModal:userReducers.devicesUserModal
    
  },
});

export default store;
